import React from "react";
import Layout from "../components/Layout";
import "../scss/products.scss";
import { StaticImage } from "gatsby-plugin-image";
import SnackSeries from "../components/ProductsHome/SnackSeries";
import JSeries from "../components/ProductsHome/JSeries";
import Helmet from "react-helmet";

const Products = (props) => {
  return (
    <Layout
      title="Plant-based products that are made 100% from plants"
      description="FIRST PRIDE plant-based products, made 100% from plants. A healthier option that tastes just like meat | FIRST PRIDE SG"
    >
      <Helmet>
        <link rel="alternate" href="https://www.firstpride.sg/products" hreflang="en-sg" />
        <link rel="alternate" href="https://www.firstpridethailand.com/products/" hreflang="en-th" />
      </Helmet>
      <div className="products">
        <div className="productsBanner">
          <div className="productsBannerContent" style={{ whiteSpace: "pre-wrap" }}>
            <h1>Plant-based Products from FIRST PRIDE</h1>
            <h5 className="p-regular">
              {"THINK "}
              <b>{"Plant-based "}</b>
              {"IS BORING? THINK AGAIN."}
              <br />
              {"FIRST PRIDE OFFERS A DELICIOUS RANGE OF "}
              <b>{"PLANT-BASED MEAT "}</b>
              {"\n THAT’S EASY TO ENJOY."}
            </h5>
          </div>
        </div>
        <div className="productsContentWrapper" style={{ whiteSpace: "pre-wrap" }}>
          <div className="productsHeader anchor-prod" id="snack">
            <StaticImage src="../img/snackseries.webp" alt="SnackSeries" className="productsImage" />
            <div className="productsContentTitle">
              <h2>Snack Series</h2>
              <p className="p-regular">
                Tasty and healthy snacks
                <br />
                to perk up your day
              </p>
            </div>
          </div>
          <div className="productsSnackSeries">
            <SnackSeries page="products" />
          </div>
          {/* j series  block
          <div className="productsHeader anchor-prod" id="j">
            <StaticImage src="../img/J-series.webp" alt="SnackSeries" className="productsImage" />
            <div className="productsContentTitle">
              <h2>J Series</h2>
              <p className="p-regular" style={{ color: "var(--darkorange)" }}>
                Allium-free plant-based products
              </p>
            </div>
          </div>
          <div className="productsSnackSeries">
            <JSeries page="products" />
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Products;
